@font-face {
  font-family: 'Framework7 Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Framework7Icons-Regular.eot);
  src: local('Framework7 Icons'),
       local('Framework7Icons-Regular'),
       url(../fonts/Framework7Icons-Regular.woff2) format("woff2"),
       url(../fonts/Framework7Icons-Regular.woff) format("woff"),
       url(../fonts/Framework7Icons-Regular.ttf) format("truetype");
}

.f7-icons, .framework7-icons {
  font-family: 'Framework7 Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  font-feature-settings: "liga";
  text-align: center;
}


/**
 * @license
 * MyFonts Webfont Build ID 3276557, 2016-08-30T15:53:59-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: FF DIN Web Pro Bold by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-din/pro-bold/
 *
 * Webfont: FF DIN Web Pro by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-din/pro-regular/
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3276557
 * Licensed pageviews: 50,000
 * Webfonts copyright: 2009 Albert-Jan Pool published by FSI FontShop International GmbH
 *
 * © 2016 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/31ff0d");*/


@font-face {

  font-family: 'FFDINWebProBold';
  src: url(../fonts/31FF0D_0_0.eot);
  src: url(../fonts/31FF0D_0_0.eot?#iefix) format('embedded-opentype'),
        url(../fonts/31FF0D_0_0.woff2) format('woff2'),
        url(../fonts/31FF0D_0_0.woff) format('woff'),
        url(../fonts/31FF0D_0_0.ttf) format('truetype'),
        url(../images/31FF0D_0_0.svg#wf) format('svg');}

@font-face {
  font-family: 'FFDINWebPro';
  src: url(../fonts/31FF0D_1_0.eot);
  src: url(../fonts/31FF0D_1_0.eot?#iefix) format('embedded-opentype'),
        url(../fonts/31FF0D_1_0.woff2) format('woff2'),
        url(../fonts/31FF0D_1_0.woff) format('woff'),
        url(../fonts/31FF0D_1_0.ttf) format('truetype'),
        url(../images/31FF0D_1_0.svg#wf) format('svg');}
/*

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'),
  		url("../fonts/nQhiC-wSiJx0pvEuJl8d8A.eot"),
        url("../fonts/GtRkRNTnri0g82CjKnEB0Q.woff2") format("woff2"),
      	url("../fonts/boeCNmOCCh-EWFLSfVffDg.woff") format("woff"),
      	url("../fonts/5FbdueAKJ1eeO4CsvGhNRevvDin1pK8aKteLpeZ5c0A.ttf") format("truetype"),
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: local('Lato Hairline'), local('Lato-Hairline'),
  		url("../fonts/nQhiC-wSiJx0pvEuJl8d8A.eot"),
        url("../fonts/GtRkRNTnri0g82CjKnEB0Q.woff2") format("woff2"),
      	url("../fonts/boeCNmOCCh-EWFLSfVffDg.woff") format("woff"),
      	url("../fonts/5FbdueAKJ1eeO4CsvGhNRevvDin1pK8aKteLpeZ5c0A.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
  		url("../fonts/nQhiC-wSiJx0pvEuJl8d8A.eot"),
        url("../fonts/EsvMC5un3kjyUhB9ZEPPwg.woff2") format("woff2"),
      	url("../fonts/KT3KS9Aol4WfR6Vas8kNcg.woff") format("woff"),
      	url("../fonts/0DeoTBMnW4sOpD0Zb8OQSALUuEpTyoUstqEm5AMlJo4.ttf") format("truetype");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'),
  		url("../fonts/nQhiC-wSiJx0pvEuJl8d8A.eot"),
        url("../fonts/EsvMC5un3kjyUhB9ZEPPwg.woff2") format("woff2"),
      	url("../fonts/KT3KS9Aol4WfR6Vas8kNcg.woff") format("woff"),
      	url("../fonts/0DeoTBMnW4sOpD0Zb8OQSALUuEpTyoUstqEm5AMlJo4.ttf") format("truetype");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215;
}

*/
